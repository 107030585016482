.root {
  cursor: default;
}

.text {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;
}
