.root {
  background-color: inherit;
  border-bottom: 1px solid var(--palette-grey-300);
}
.container {
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.gutterBegin {
  padding-left: calc(2 * var(--mini-unit));
}
.gutterEnd {
  padding-right: calc(2 * var(--mini-unit));
}
